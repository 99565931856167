import React, { useState, useEffect, useMemo, useRef } from "react"
import { Link } from "react-router-dom"
import "./style.scss"

const data = {
  ru: {
    title: "Результаты наших клиентов",
    textOne:
      "Наш экспертный опыт гарантирует быструю и качественную разработку вашего продукта. Проектируем и разрабатываем не только «для Заказчика», но и сами гордимся итогами работы.",
    textTwo:
      "Наши ИТ-решения дают бизнесу реальный результат: помогают привлекать клиентов, снижать издержки и увеличивать прибыль.",
    results: [
      {
        titleOrange: "В 3 раза ",
        title: "сократили время на расчет страховых резервов",
        link: "/portfolio/case/intecreservesautomation/ru",
        text: "Автоматизация расчетов не только сократила количество затрачиваемых человеко-часов. Устранены ошибки  из-за человеческого фактора. Автоматически контролируется соблюдение законодательных нормативов.",
        image: "/img/intek-project-1.webp",
      },
      {
        titleOrange: "28 млн ₽ ",
        title: "в год сэкономили за счет оптимизации бизнес-процессов",
        link: "/portfolio/case/avisk/ru",
        text: "Внедрение ИТ-решений в бизнес-процессы  значительно экономит ресурсы. Проявляет точки роста и оптимизации, позволяет более точно планировать расходы будущих периодов.",
        image: "/img/avisk-project-1.webp",
      },
      {
        titleOrange: "99,99% ",
        title: "отказоустойчивость сайтов Интернет-магазинов",
        link: "/portfolio/case/yutstroi/ru",
        text: "Внедрение микросервисной архитектуры позволяет сайтам выдерживать большую нагрузку в пиковые моменты посещаемости. Каждый запрос клиента обрабатывается быстро и без ошибок. А следовательно и прибыль Заказчика растет.",
        image: "/img/citadel-project-1.webp",
      },
      {
        titleOrange: "В 6,5 раз ",
        title:
          "ускорили бизнес-процессы за счет внедрения Искусственного интеллекта",
        link: "/portfolio/case/ai/ru",
        text: "Разработанное ИТ-решение повышает скорость обслуживания, тем самым повышая уровень сервиса для клиентов. Максимальная эффективность достигнута за счет точной интеграции в работу всех задействованных рабочих систем.",
        image: "/img/ai-project-1.webp",
      },
    ],
  },
  en: {
    title: "Our Clients’ Results",
    textOne:
      "Our expertise ensures fast, high-quality product development. We design and build solutions not just for our clients, but with pride in the outcomes we deliver.",
    textTwo:
      "Our IT solutions drive real business impact: attracting customers, reducing costs, and increasing profits.",
    results: [
      {
        titleOrange: "3x ",
        title: "faster insurance reserve calculations",
        link: "/portfolio/case/intecreservesautomation/en",
        text: "Process automation has significantly reduced manual labor while eliminating human errors. Compliance with regulatory standards is now automatically monitored.",
        image: "/img/intek-project-1.webp",
      },
      {
        titleOrange: "$300,000 ",
        title: "saved annually through business process optimization",
        link: "/portfolio/case/avisk/en",
        text: "IT solutions help businesses cut costs, identify growth opportunities, and plan expenses more accurately.",
        image: "/img/avisk-project-1.webp",
      },
      {
        titleOrange: "99,99% ",
        title: "uptime for e-commerce websites",
        link: "/portfolio/case/yutstroi/en",
        text: "Microservices architecture ensures stability during peak traffic periods. Every customer request is processed quickly and without errors, leading to increased revenue.",
        image: "/img/citadel-project-1.webp",
      },
      {
        titleOrange: "6.5x ",
        title: "faster business processes through AI integration",
        link: "/portfolio/case/ai/en",
        text: "The implemented IT solution boosts service speed and enhances customer experience. Maximum efficiency is achieved through seamless integration with all operational systems.",
        image: "/img/ai-project-1.webp",
      },
    ],
  },
}

export const CustomerResultsSection = ({ lang }) => {
  const [active, setActive] = useState(1)
  const [width, setWidth] = useState(0)
  const isMobile = useMemo(() => (width && width < 768 ? true : false), [width])

  const accordionRefs = useRef([])

  const show = (e, i) => {
    e.stopPropagation()
    active === i + 1 ? setActive(0) : setActive(i + 1)
  }

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth)
    }
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      accordionRefs.current.forEach((el, i) => {
        if (el) {
          const rect = el.getBoundingClientRect()
          const elementCenter = rect.top + rect.height / 2
          const windowCenter = window.innerHeight / 2
          if (elementCenter >= windowCenter - 100 && elementCenter <= windowCenter + 100) {
            setActive(i + 1)
          }
        }
      })
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const contentRef = useRef(null)
  const imageRef = useRef(null)
  return (
    <section className="results-section">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="description-wrap">
          <p className="description">{data[lang].textOne}</p>
          <p className="description">{data[lang].textTwo}</p>
        </div>
        <div className="results-accordion">
          {data[lang].results.map((e, i) => {
            return (
              <div
                className="accordion-item"
                key={`accordion-item-${i}`}
                ref={(el) => (accordionRefs.current[i] = el)}
                onClick={() => setActive(i + 1)}
              >
                <div className="item-wrap">
                  <div className={`item-number ${active === i + 1 ? "active" : ""}`}>
                    {isMobile ? "" : "0"}
                    {i + 1}
                  </div>
                  <div className="item-title-wrap">
                    <div className="item-title">
                      <span className="title__orange">{e.titleOrange}</span>
                      {e.title}
                    </div>
                    <div
                      className={`animated-wrapper ${active === i + 1 ? "open" : ""}`}
                      ref={contentRef}
                      style={{
                        maxHeight: active === i + 1 ? contentRef.current?.scrollHeight : 0,
                      }}
                    >
                      <Link to={e.link}>
                        <div className="item-text">{e.text}</div>
                      </Link>
                    </div>
                    {isMobile && active === i + 1 && (
                      <div className="animated-img-wrapper" ref={imageRef}>
                        <Link to={e.link}>
                          <div>
                            <img className="item-img img-margin" src={e.image} alt="accordion-img" />
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`animated-img-wrapper ${!isMobile && active === i + 1 && 'open'}`} ref={imageRef}>
                  <Link to={e.link}>
                    <div>
                      <img className="item-img" src={e.image} alt="accordion-img" />
                    </div>
                  </Link>
                </div>
                <div onClick={(e) => show(e, i)} className="icon-open">
                  <img src={`/img/${active === i + 1 ? "minus" : "plus"}.svg`} alt="icon-open" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}